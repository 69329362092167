import React from 'react';
import { StructuredTextDocument } from 'react-datocms';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import CheckMark from './check-mark.svg';
import StyledButton, { StyledButtonAsLink } from '../../../primitives/styled-button';
import { getLink } from '../../../primitives/link';
import VideoPopover from '../../../primitives/video-popover';
import PlayButton from './play-button.svg';
import type { HeroProps } from './index';
import { breakpoints } from '../../../primitives/tokens';

export const HeroContext = React.createContext(false);
const Content = ({
    richTitle, text, buttons, checkmarks, centered, bigTitleOnMobile, withMaxWidth = true,
}: HeroProps & { centered?: boolean, bigTitleOnMobile?: boolean, withMaxWidth?: boolean }): JSX.Element => (
    <HeroContext.Provider value>
        <Typography
            fontSize={{
                dt: 6368,
                tb: bigTitleOnMobile ? 5660 : 4053,
                mb: bigTitleOnMobile ? 4053 : 3234,
            }}
            fontWeight="light"
            override
            css={{
                textAlign: centered ? 'center' : 'left',
                [breakpoints.mb]: {
                    textAlign: 'center',
                    margin: 'auto',
                },
            }}
        >
            <CustomStructuredTextRenderer
                css={{
                    lineHeight: 1.1,
                }}
                as="h1"
                data={richTitle as unknown as StructuredTextDocument}
            />
        </Typography>
        {text && (
            <Typography
                fontSize={{
                    dt: 1822,
                }}
                css={{
                    maxWidth: (() => {
                        if (centered) {
                            return '650px';
                        }
                        if (withMaxWidth) {
                            return '550px';
                        }
                        return 'unset';
                    })(),
                    paddingTop: '36px',
                    textAlign: centered ? 'center' : 'left',
                    margin: centered ? 'auto' : 'unset',
                    opacity: 0.7,
                    [breakpoints.mb]: {
                        textAlign: 'center',
                        margin: 'auto',
                        paddingTop: '20px',
                    },
                }}
            >
                {text}
            </Typography>
        )}
        {checkmarks && checkmarks.length > 0 && (
            <div css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: centered ? 'center' : 'flex-start',
                columnGap: '36px',
                rowGap: '8px',
                flexFlow: 'wrap',
                paddingTop: '28px',
                [breakpoints.mb]: {
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    columnGap: '20px',
                    rowGap: '2px',
                },
            }}
            >
                {checkmarks.map((checkmark) => {
                    if (!checkmark) return null;

                    const { text: checkmarkText } = checkmark;

                    return (
                        <div css={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            [breakpoints.mb]: {
                                gap: '6px',
                            },
                        }}
                        >
                            <CheckMark />
                            <Typography
                                as="span"
                                fontSize={{
                                    dt: 1526,
                                    mb: 1226,
                                }}
                            >
                                {checkmarkText}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        )}
        {buttons && buttons.length > 0 && (
            <div css={{
                display: 'flex',
                gap: '16px',
                width: '100%',
                paddingTop: '38px',
                justifyContent: centered ? 'center' : 'flex-start',
                [breakpoints.mb]: {
                    justifyContent: 'center',
                    margin: 'auto',
                    paddingTop: '34px',
                    flexDirection: 'column',
                },
            }}
            >
                {buttons.map((button) => {
                    if (!button) return null;

                    const { __typename: typename } = button;

                    if (typename === 'DatoCmsLinkWithTitle') {
                        return (
                            <StyledButtonAsLink
                                css={{
                                    width: '148px',
                                }}
                                to={getLink(button?.link)}
                            >
                                {button.title}
                            </StyledButtonAsLink>
                        );
                    }
                    if (typename === 'DatoCmsVideoPopoverButton') {
                        return (
                            <VideoPopover video={button.video} link={button.link}>
                                <StyledButton
                                    variant="secondary"
                                    css={{
                                        width: '148px',
                                    }}
                                >
                                    <PlayButton />
                                    <span>{button.title}</span>
                                </StyledButton>
                            </VideoPopover>
                        );
                    }
                    return null;
                })}
            </div>
        )}
    </HeroContext.Provider>
);

export default Content;
